import { VCheckboxBtn } from 'vuetify/components'
import type { AccountSelection } from '~/types/Account'
import type { Dealer, DeviceData, Subdealer } from '~/types/Device'
import type { FilterItemValue } from '~/types/Filter'
import type { Header, SortDirection, SortItem } from '~/types/GenericTable'

export const useTableData = () => {
  const { useComponent } = useCustomComponents()
  const defaultDevicesHeaders: Header<DeviceData>[] = [
    {
      title: 'Read only',
      value: 'readOnly',
      align: 'start',
      sortable: true,
      visible: true,
      nowrap: true,
      itemSlot: 'item.readOnly',
      components: (data: DeviceData) => {
        const checkboxBtn = useComponent(VCheckboxBtn, {
          density: 'comfortable',
          modelValue: data.readOnly,
          onClick: () => data.readOnly = !data.readOnly,
        })
        return [checkboxBtn]
      },
    },
    {
      title: 'Hexacode',
      value: 'hexacode',
      align: 'start',
      sortable: true,
      visible: true,
      nowrap: true,
      itemSlot: 'item.hexacode',
    },
    {
      title: 'Name',
      value: 'name',
      align: 'start',
      sortable: true,
      visible: true,
      nowrap: true,
      itemSlot: 'item.name',
    },
  ]

  function mapDealers(dealers: Dealer[]): FilterItemValue<string>[] {
    return dealers.map(d => ({ title: d.name, value: d.name }))
  }
  function mapSubDealers(subDealers: Subdealer[]): FilterItemValue<string>[] {
    return subDealers.map(d => ({ title: d.name, value: d.name }))
  }
  function mapTags(tags: string[]): FilterItemValue<string>[] {
    return tags.map(d => ({ title: d, value: d }))
  }
  function mapAccounts(users: AccountSelection[]): FilterItemValue<string>[] {
    return users.map(d => ({ title: d.username, value: d.id }))
  }

  function getSortDirection(sortBy: SortItem[]): SortDirection {
    if (sortBy.length > 0) {
      const order = sortBy[0].order
      if (typeof order === 'string') {
        return order.toUpperCase() as SortDirection
      }
    }
    return 'ASC'
  }

  function getSortField(sortBy: SortItem[]) {
    if (sortBy.length > 0) {
      return sortBy[0].key
    }
    return ''
  }

  function getRawHexacode(formattedHexacode: string) {
    if (formattedHexacode) {
      return parseInt(formattedHexacode.replaceAll('.', ''), 16)
    }
    return null
  }

  function computeHostTypeColor(host: string) {
    if (host === 'VS100_01-01.00') {
      return 'teal lighten-1'
    } else if (host === 'BG101_01-01.00') {
      return 'blue lighten-1'
    }
    return 'deep-purple lighten-1'
  }
  return {
    getSortDirection,
    getSortField,
    getRawHexacode,
    mapDealers,
    mapSubDealers,
    mapAccounts,
    mapTags,
    defaultDevicesHeaders,
    computeHostTypeColor,
  }
}
